import { FC } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useSession } from 'next-auth/react';

import { Budge, Icon } from '@components';

import classes from './HeaderTopFavorites.module.scss';

const AuthModal = dynamic(() => import('@components/modals').then((mod) => mod.AuthModal));

interface IProps {
  favoritesQty?: number;
}

const HeaderTopFavorites: FC<IProps> = ({ favoritesQty }) => {
  const { status: sessionStatus } = useSession();

  return (
    <>
      {sessionStatus === 'authenticated' ? (
        <Link className={classes.favoritesLink} href="/tp/profile/favorites" aria-label='Улюблені товари'>
          <Icon type="heart" size={24} />
          <Budge label={favoritesQty} />
        </Link>
      ) : (
        <AuthModal>
          <Icon type="heart" size={24} />
        </AuthModal>
      )}
    </>
  );
};

export default HeaderTopFavorites;
