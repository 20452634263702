import { FC, useState } from 'react';
import Link from 'next/link';

import { ExpandPanel } from '@components';

import classes from './FooterNavGroup.module.scss';

export interface INavLinkData {
  url: string;
  title: string;
}

export interface IFooterNavGroupData {
  title: string;
  links: INavLinkData[];
  className?: string;
}

const FooterNavGroup: FC<IFooterNavGroupData> = ({ links, title, className }) => {
  return (
    <div className={className}>
      <h5 className={classes.groupTitle}>{title}</h5>
      <nav>
        <ul className={classes.list}>
          {links.map((link, index) => {
            return (
              <li key={index}>
                <Link href={link.url} className={classes.linkItem} aria-label={link.title}>
                  {link.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

const FooterNavGroupAccordion: FC<IFooterNavGroupData> = ({ title, links }) => {
  const [expanded, setExpanded] = useState(false);

  function handleChange() {
    setExpanded(!expanded);
  }

  return (
    <ExpandPanel
      label={<h3 className={classes.groupTitle}>{title}</h3>}
      onChange={handleChange}
      expanded={expanded}
      className={classes.root}
      classNameSummary={classes.summary}
      iconSize={16}
    >
      <ul className={classes.list}>
        {links.map((link, index) => {
          return (
            <li key={index}>
              <Link href={link.url} className={classes.linkItem} aria-label={link.title}>
                {link.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </ExpandPanel>
  );
};

export { FooterNavGroupAccordion };
export default FooterNavGroup;
