import { FC } from 'react';
import clsx from 'clsx';

import { HeaderTop } from '../../header/HeaderTop';
import { HeaderNavigationMenu } from '../HeaderNavigationMenu/HeaderNavigationMenu';

import classes from '../HeaderMain.module.scss';

interface IProps {
  headerTopRef: React.RefObject<HTMLDivElement>;
  isScrolled: boolean;
}

const HeaderDesktop: FC<IProps> = ({ headerTopRef, isScrolled }) => (
  <div className={classes.wrapper}>
    <div
      ref={headerTopRef}
      className={clsx({
        [classes.headerTopHidden]: isScrolled,
        [classes.headerTopVisible]: !isScrolled
      })}
    >
      {!isScrolled && <HeaderTop isScrolled={isScrolled} />}
    </div>
    <HeaderNavigationMenu isScrolled={isScrolled} />
  </div>
);

export { HeaderDesktop };
