import React from 'react';
import { FlagIcon } from 'react-flag-kit';
import dynamic from 'next/dynamic';

import { SocialLinks } from '@components';
import { staticPages } from '@lib/constants/static-pages';
import FooterNavGroup from '../FooterNav/FooterNavGroup';

import classes from './More.module.scss';

const PlacesAutocompleteModal = dynamic(() => import('@components/modals').then((mod) => mod.PlacesAutocompleteModal));

const aboutUsLinks = [staticPages.aboutUs, staticPages.contacts, staticPages.shops];
const forClientLinks = [
  staticPages.delivery,
  staticPages.payment,
  staticPages.blog,
  staticPages.replacement,
  staticPages.publicOferta
];
const phone = '+380 63 999 99 11';

export const More = () => {
  return (
    <>
      <div>
        <div className={classes.containerCountry}>
          <span className={classes.country}>
            Мова: <FlagIcon code={'UA'} size={12} className={classes.countryLabel} /> UA
            {/* <Icon type="arrowExpand" size={8} opacity={1} /> */}
          </span>
          <span className={classes.currency}>
            Валюта: Грн
            {/* <Icon type="arrowExpand" size={8} opacity={1} /> */}
          </span>
        </div>
        <PlacesAutocompleteModal className={classes.address} />
      </div>
      <FooterNavGroup title="Про компанію" links={aboutUsLinks} className={classes.navMenu} />
      <FooterNavGroup title="Клієнтам" links={forClientLinks} className={classes.navMenu} />
      <SocialLinks iconSize={44} className={classes.socialLinks} />
      <p className={classes.phoneNumber}>
        <a href={`tel:${phone}`}>{phone}</a>
      </p>
    </>
  );
};
