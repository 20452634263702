import dynamic from 'next/dynamic';

import { MainLogoLink } from '@components';

import classes from '../HeaderMain.module.scss';

const PhoneLink = dynamic(() => import('../../../components/shared/PhoneLink/PhoneLink'));

const HeaderSearchMobile = dynamic(() => import('../search/HeaderSearchMobile').then((mod) => mod.HeaderSearchMobile));

const HeaderMobile = () => (
  <div className={classes.mobileHeader}>
    <MainLogoLink width={138} height={25} />
    <div className={classes.mobileContainer}>
      <PhoneLink withPopup onlyIcon position="bottom right" />
      <HeaderSearchMobile />
    </div>
  </div>
);

export { HeaderMobile };
