'use client';

import dynamic from 'next/dynamic';

import { useIsMobile } from '@contexts/mobile-detector-provider';
import { staticPages } from '@lib/constants/static-pages';
import { FooterSocialLinks } from './footer-social-links/FooterSocialLinks';
import { ContactsInfo } from './FooterContacts/ContactsInfo';
import FooterNavGroup from './FooterNav/FooterNavGroup';
import NavDevice from './NavDevice/NavDevice';

import classes from './footer.module.scss';

const FooterNavGroupAccordion = dynamic(() =>
  import('./FooterNav/FooterNavGroup').then((mod) => mod.FooterNavGroupAccordion)
);

const aboutUsLinks = [staticPages.aboutUs, staticPages.contacts, staticPages.shops];
const forClientLinks = [
  staticPages.delivery,
  staticPages.payment,
  staticPages.blog,
  staticPages.replacement,
  staticPages.publicOferta
];

const Footer = () => {
  const { isMobile } = useIsMobile();

  return (
    <>
      <footer className={classes.footerWrapper}>
        {isMobile ? (
          <div className={classes.footerContainer}>
            <FooterSocialLinks logoHeight={26} logoWidth={145} />
            <FooterNavGroupAccordion title="Про компанію" links={aboutUsLinks} />
            <FooterNavGroupAccordion title="Клієнтам" links={forClientLinks} />
            <ContactsInfo withoutTitle />
          </div>
        ) : (
          <div className={classes.footerContainer}>
            <FooterSocialLinks />
            <FooterNavGroup title="Про компанію" links={aboutUsLinks} />
            <FooterNavGroup title="Клієнтам" links={forClientLinks} />
            <ContactsInfo />
          </div>
        )}
      </footer>
      {isMobile ? <NavDevice /> : null}
    </>
  );
};

export default Footer;
